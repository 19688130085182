import React from "react"
import { Link } from "gatsby"

import Layout from "../layouts"

const About = () => (
  <Layout title="About">
    <div className="container is-widescreen">
      <div className="columns">
        <div className="column is-8 is-offset-2">
          <h1 className="title has-text-primary has-text-centered">
            Sphere Content
          </h1>
          <div className="content">
            <p>
              <strong>SPHERE CONTENT</strong> is an IP Management & Content Development company. Assisted by an
              international
              legal studio specialized in IP and copyright, we manage creative and innovative concepts aimed at becoming
              the
              network series, the full features, or the animation projects of tomorrow.
            </p>
            <p>
              We develop these concepts thoroughly, taking them to the next level.
            </p>
            <p>
              There are different possibilities available to our clients:
            </p>
            <p>
              • a designated option period (usually 6 or 12 months) for a specified fee;<br/>
              • the project’s full IP purchase;<br/>
              • a co-development purchase (at a lower fee than the full IP purchase), through which our writing team can
              partner your key personnel in further developing the chosen project, tailoring it around your needs and
              expectations.
            </p>
            <p>
              After all, our team comprises a lineup of authors and creative talent based across the globe.
              This allows us to offer you a diverse and broad range of projects, covering different genres and languages
              (i.e. different target audiences).
            </p>
            <p>
              At present we boast over 50 titles in development,
              both <strong>original</strong> and <strong>derivative</strong>. Some of these are part of our extended
              offline
              catalog.
            </p>
            <p>
              If you cannot find a specific project online, please contact us so that we can provide you with material
              that
              might meet your requirements.
            </p>
            <p>
              We would be extremely pleased to schedule a brief virtual meeting with you, to evaluate any potential
              synergies among our companies.
            </p>
          </div>

          <div className="has-text-centered">
            <Link to="/contact-us" className="button is-primary is-large is-rounded action-button ">
              <span className="icon is-medium">
                <i className="far fa-comment"/>
              </span>
              <span>Contact us</span>
            </Link>
          </div>
        </div>
      </div>
    </div>
  </Layout>
)

export default About